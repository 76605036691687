import axios from "axios";

export default {
  async getRimborsi(userID: string) {
    const data = new FormData();
    data.append("where[rimborsi_km_agente]", userID.toString());
    data.append("orderby", "rimborsi_km_data");
    data.append("orderdir", "desc");

    const response = await axios.post(`rest/v1/search/rimborsi_km`, data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    console.log(response);
    return response.data.data;
  },

  async saveRimborso(rimborso) {
    const response = await axios.post(`rest/v1/create/rimborsi_km`, rimborso, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    console.log(response);
    return response;
  },
};
