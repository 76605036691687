<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title color="white">Dettaglio nota spesa</ion-title>
        <ion-buttons slot="start">
          <ion-button @click="closeModal()">
            <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="page_content">
        <div class="richiesta">
          <div class="field">
            <div class="title">Data</div>
            <div class="value">
              {{ dateFormat(spesa.rimborsi_km_data) }}
            </div>
          </div>
          <div class="field" v-if="spesa.rimborsi_km_cliente">
            <div class="title">Cliente</div>
            <div class="value">
              {{ setShortCustomer(spesa) }}
            </div>
          </div>
          <div class="field" v-if="spesa.rimborsi_km_clienti">
            <div class="title">Cliente</div>
            <div class="value">
              {{ setShortCustomer(spesa) }}
            </div>
          </div>
          <div class="field">
            <div class="title">Luogo</div>
            <div class="value">
              {{ spesa.rimborsi_km_luogo ? spesa.rimborsi_km_luogo : "-" }}
            </div>
          </div>
          <div class="field">
            <div class="title">Costo viaggio</div>
            <div class="value">€ {{ parseFloat(spesa.rimborsi_km_costo_viaggio).toFixed(2) }}</div>
          </div>
          <div class="field_note">
            <div class="title">Motivo</div>
            <div class="value">
              {{ spesa.rimborsi_km_motivo }}
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  //IonBackButton,
  IonTitle,
  IonContent,
  IonIcon,
  IonButton,
  modalController,
} from "@ionic/vue";

import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { arrowBackOutline } from "ionicons/icons";

import moment from "moment";

import { dateFormat } from "../services/utils";

export default defineComponent({
  name: "RimborsoDetail",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
  },
  setup(props, context) {
    const route = useRoute();
    const id = route.params.id; //spesa_id by route params
    const router = useRouter();
    const loading = ref(false);
    const spesa = ref([]);

    //console.log(props.data);

    spesa.value = { ...props.data };
    //console.log(spesa.value);

    /**
     * Handle close modal by clicking on Chiudi button
     */
    const closeModal = () => {
      modalController.dismiss();
    };

    /**
     * ! Limit rimborso customer length to 40 charactes
     */
    function setShortCustomer(rimborso) {
      if (rimborso.rimborsi_km_cliente) {
        if (rimborso.customers_company) {
          const trimmedString = rimborso.customers_company.length > 40 ? rimborso.customers_company.substring(0, 37) + "..." : rimborso.customers_company;
          return trimmedString;
        } else {
          const concat = `${rimborso.customers_name} ${rimborso.customers_last_name}`;
          const trimmedString = concat.length > 40 ? concat.substring(0, 37) + "..." : concat;
          return trimmedString;
        }
      } else if (rimborso.rimborsi_km_clienti) {
        const trimmedString = rimborso.rimborsi_km_clienti.length > 40 ? rimborso.rimborsi_km_clienti.substring(0, 37) + "..." : rimborso.rimborsi_km_clienti;
        return trimmedString;
      } else {
        return "";
      }
    }

    return {
      id,
      loading,
      dateFormat,
      spesa,
      arrowBackOutline,
      closeModal,
      setShortCustomer,
    };
  },
});
</script>

<style scoped>
ion-toolbar {
  --background: #086fa3;
  --color: #ffffff;
}
.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

ion-item {
  font-size: 14px;
}
ion-label {
  margin: 0;
}

.description_title {
  color: var(--ion-color-primary, #3880ff) !important;
  margin: 10px 0;
}

/* New rules */
.page_content {
  width: 100%;
  position: relative; /* to be used for new richiesta btn */
  padding: 16px;
  min-height: 100%;
  background: #f8fafc;
}

.richiesta {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.richiesta .field {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 6px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e2e8f0;
}
.richiesta .field_note {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 12px 6px;
  border-bottom: none;
}
.richiesta .field_ricevuta {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 12px 6px;
}
.field .title,
.field_note .title,
.field_ricevuta .title {
  font-size: 14px;
  font-weight: 500;
  color: #475569;
}
.field_ricevuta .title {
  margin-bottom: 8px;
}
.field_note .title {
  margin-bottom: 8px;
}
.field .value,
.field_note .value,
.field_ricevuta .value {
  font-size: 14px;
}

.value .badge {
  font-size: 12px;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
}
.value .badge_success {
  background-color: rgb(220 252 231);
  color: rgb(34 197 94);
  font-weight: bold;
}
.value .badge_danger {
  background-color: rgb(254 226 226);
  color: rgb(239 68 68);
  font-weight: bold;
}
ion-button {
  --color: #ffffff;
}
</style>
